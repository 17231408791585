<template>
  <div class="th-register-pop" @click="sdClosePop">
    <div class="th-register-main" @click.stop="openRegisterPop">
      <div class="left">
        <img src="@/assets/pic/register-bg.png" />
      </div>
      <div class="right">
        <h3>注册博弈体育</h3>
        <form>
          <ul>
            <li>
              <span class="icon icon-account"></span>
              <input
                type="text"
                v-model="form.account"
                class="input-wid"
                placeholder="请输入账号"
                @input="checkFrom"
              />
            </li>
            <li>
              <span class="icon icon-lock"></span>
              <input
                :type="inputType ? 'password' : 'text'"
                v-model="form.pwd"
                placeholder="请输入密码"
                class="input-wid"
                @input="checkFrom"
              />
              <span
                class="icon-eyy-on type"
                :class="inputType ? 'icon-eyy-on' : 'icon-eyy-off'"
                @click="inputType = !inputType"
              ></span>
            </li>
            <li v-if="registerItemList && isFromInput(7)">
              <span class="icon icon-account"></span>
              <input
                type="text"
                v-model="form.realName"
                class="input-wid"
                placeholder="请填写真实姓名"
                @input="checkFrom"
              />
            </li>
            <li v-if="areaList && registerItemList && isFromInput(1)">
              <span class="icon icon-phone"></span>
              <el-select
                v-model="form.area"
                placeholder="请选择"
                class="th-select-style"
                style="width: 76px"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                type="number"
                v-model="form.phone"
                maxlength="11"
                class="input-wid"
                placeholder="请输入手机号码"
                @input="checkFrom"
              />
            </li>
            <li v-if="registerItemList && isFromInput(15)">
              <span class="icon icon-current"></span>
              <el-select
                v-model="form.current"
                placeholder="请选择"
                class="input-wid th-select-style"
              >
                <el-option
                  v-for="item in moneyList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
            <li v-if="registerItemList && isFromInput(12)">
              <span class="icon icon-code"></span>
              <input
                type="text"
                v-model="form.smsCode"
                class="input-wid"
                placeholder="短信验证码"
                @input="checkFrom"
              />
              <span class="seng">获取验证码</span>
            </li>
            <li v-if="registerItemList && isFromInput(18)">
              <span class="icon icon-ivtive"></span>
              <input
                type="text"
                v-model="form.invite"
                class="input-wid"
                placeholder="邀请码"
                @input="checkFrom"
              />
            </li>
          </ul>
        </form>
        <div class="th-from-xy">
          <label @click="isXy = !isXy">
            <span class="form-check" :class="{ checkXy: isXy }"></span>
            注册即表示同意 <span class="text-glod">《博弈体育规则与条款》</span>
          </label>
        </div>
        <div class="th-from-link">
          <button
            class="th-link"
            :class="{ 'th-link-no': isLink }"
            @click="submitCheck"
          >
            注册
          </button>
        </div>
        <div class="th-from-login">
          我已经注册，<span class="text-glod" @click="gotoLogin"
            >现在登录>></span
          >
        </div>
      </div>
    </div>
    <Slider
      v-if="show"
      :visible.sync="show"
      ref="sliderComponent"
      :fromValue="form"
      :log="true"
    ></Slider>
  </div>
</template>
<script>
import { setToken } from "@/utils/token";
import Slider from "@/components/Slider.vue";
export default {
  components: {
    Slider,
  },
  data() {
    return {
      show: false,
      form: {
        account: "",
        pwd: "",
        area: "",
        phone: "",
        current: "",
        realName: "",
        smsCode: "",
        invite: "",
      },
      codeImg: "",
      moneyList: null,
      registerItemList: null,
      areaList: null,
      inputType: true,
      isXy: true,
      isLink: true,
    };
  },
  created() {
    this.$bus.$on("aevent", (params) => {
      this.validImg(params);
    });
  },
  mounted() {
    this.getRegisterItem();
    this.getMoneyType();
    this.getArea();
  },
  methods: {
    submitCheck() {
      this.show = true;
    },
    validImg(data) {
      this.codeUUid = data;
      this.registerSend();
    },
    sdClosePop() {
      this.$router.push("/");
      this.closeRegisterPop();
    },
    gotoLogin() {
      this.closeRegisterPop();
      this.openLoginPop();
    },
    checkFrom() {
      let isFill = false;
      // 账号正则,必须由数字字母组成
      let accountBoolean = /(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z]+/;
      Object.keys(this.form).map((item) => {
        if (!this.form[item] && item !== "invite") {
          isFill = true;
        }
        // 账号必须由数字字母组成
        if (item === "account" && !accountBoolean.test(this.form[item])) {
          isFill = true;
        }
        // 手机号码必须11位
        if (item === "phone" && this.from[item].length !== 11) {
          isFill = true;
        }
      });
      this.isLink = isFill;
    },
    isFromInput(val) {
      const isShow = this.registerItemList.find(
        (item) => item.id === val
      ).isShow;
      return isShow === 1 ? true : false;
    },
    getMoneyType() {
      this.$Api
        .getSysDictionary({
          dictionaryValue: "currency_type",
        })
        .then((res) => {
          this.moneyList = res.sysDictionaryList;
          this.form.current = res.sysDictionaryList[0].value;
        });
    },
    getArea() {
      this.$Api
        .getSysDictionary({
          dictionaryValue: "area_code",
        })
        .then((res) => {
          res.sysDictionaryList.forEach((item) => {
            item.name = "+" + item.value;
          });
          this.areaList = res.sysDictionaryList;
          this.form.area = res.sysDictionaryList[0].value;
        });
    },
    getRegisterItem() {
      this.$Api.getRegisterItem().then((res) => {
        if (res) {
          this.registerItemList = res.registerItemList;
          let fromCheck = [
            {
              type: "phone",
              id: 1,
            },
            {
              type: "current",
              id: 15,
            },
            {
              type: "smsCode",
              id: 12,
            },
            {
              type: "invite",
              id: 18,
            },
            {
              type: "realName",
              id: 7,
            },
          ];
          res.registerItemList.forEach((item) => {
            fromCheck.map((row) => {
              if (item.id === row.id) {
                item.type = row.type;
              }
            });
            if (item.isShow === 0) {
              delete this.form[item.type];
            }
            // 特殊手机号不显示同时去掉手机区号
            if (item.id === 1 && item.isShow === 0) {
              delete this.form.area;
            }
          });
        }
      });
    },
    registerSend() {
      if (!this.isXy) {
        this.$message({
          type: "warning",
          message: "请先阅读勾选规则与条款",
        });
        return;
      }
      const { account, pwd, area, phone, current, smsCode, invite, realName } =
        this.form;
      let urlparams = new URL(location.href).searchParams;
      let [directAgent, invitationCode] = [
        urlparams.get("directAgent"),
        urlparams.get("invitationCode"),
      ];
      const params = {
        userName: account,
        password: pwd,
        smsCode,
        areaCode: area,
        mobile: phone,
        invitationCode:
          invite || invitationCode || this.$route.query.invitationCode, //邀请码
        directAgent: directAgent, //代理
        currencyType: current,
        codeId: this.codeUUid,
        realName,
      };
      this.$Api.registerSend(params).then((res) => {
        if (res) {
          this.loginSend();
          return;
        }
      });
    },
    restFrom() {
      this.form = {
        account: "",
        pwd: "",
        area: "",
        phone: "",
        current: "",
        smsCode: "",
        invite: "",
      };
    },
    loginSend() {
      const { account, pwd } = this.form;
      let params = {
        captchaId: this.codeUUid,
        userName: account,
        password: pwd,
        loginType: "afterRegister",
      };
      this.$Api.loginSend(params).then((res) => {
        if (res) {
          this.$store.dispatch("setHasLoginAc", true);
          setToken(res.token);
          this.$message({
            message: this.$t("views.login.052343-18"),
            type: "success",
          });
          this.restFrom();
          this.closeRegisterPop();
          return;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.th-register-pop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
}
.th-register-main {
  background: white;
  display: flex;
  border-radius: 22px;
  overflow: hidden;
  width: 790px;
  .left {
    width: 44%;
    height: 100%;
    min-height: 518px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
    h3 {
      color: #333;
      font-size: 22px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: normal;
    }
    ul {
      width: 310px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    li {
      border: 1px solid #ebc58c;
      height: 40px;
      font-size: 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0 0 0 10px;
      .icon {
        text-align: center;
        font-size: 16px;
        color: var(--theme);
      }
      .input-wid {
        flex: 1;
        width: 100%;
        &::placeholder {
          color: #999;
        }
      }
      .type {
        width: 32px;
        text-align: center;
        font-size: 15px;
        color: #999;
      }
      .seng {
        background: #000;
        color: white;
        border-radius: 3px;
        margin-right: 4px;
        height: 28px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0 6px;
        cursor: pointer;
      }
      .imgCode {
        height: 30px;
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }
}
.form-check {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #eee;
  display: block;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  &.checkXy {
    background: var(--theme);
    border: 1px solid var(--theme);
    &::before {
      content: "";
      width: 3px;
      height: 5px;
      border: 1px solid white;
      transform: translateY(-1px) rotate(45deg);
      border-top: none;
      border-left: none;
    }
  }
}
.th-from-xy {
  font-size: 12px;
  label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
/deep/.input-wid {
  .el-input__inner {
    border: none;
    background: none;
  }
}
.th-from-link {
  width: 100%;
  .th-link {
    width: 310px;
    margin: 0 auto;
  }
}
</style>
