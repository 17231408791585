<template>
  <div class="fj-msg-pop">
    <div class="fj-msg-pop-main">
      <div class="title">{{ $t("mine.feedbackList.231182-4") }}</div>
      <ul class="message-scroll">
        <li class="message-row" v-for="(item, index) in listData" :key="index">
          <span class="title-link">{{ item.title }}</span>
          <p class="time">{{ item.createTime }}</p>
          <div class="content" v-html="item.content"></div>
        </li>
      </ul>

      <div class="main-save">
        <el-checkbox v-model="isViewMsg">{{ $t('components.Bulletin.471608-0') }}</el-checkbox>
      </div>
      <div class="message-close icon-close" @click="messageClose"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    listData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isViewMsg: false,
    };
  },
  methods: {
    messageClose() {
      if (this.isViewMsg) {
        const targetTime = new Date();
        localStorage.setItem("messageAuto", targetTime.getTime());
      }
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.fj-msg-pop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &-main {
    background: white;
    width: 600px;
    border-radius: 10px;
    position: relative;
    .title {
      font-size: 20px;
      text-align: center;
      padding: 20px 0;
      color: #000;
    }
  }
}
.message-scroll {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px 20px;
  flex-direction: column;
  display: flex;
  gap: 16px;
}
.message-row {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .title-link {
    font-size: 14px;
    color: #333;
  }
  .time {
    font-size: 12px;
    color: #666;
  }
  .content {
    color: #999;
    font-size: 16px;
  }
}
.message-close {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme1);
  color: white;
}
.main-save {
  background: #e3e3e3;
  padding: 10px;
  text-align: right;
  margin-top: 20px;
  border-radius: 0 0 10px 10px;
}
/deep/.el-checkbox__inner {
  border: 1px solid rgba(255, 255, 255, 0.3);
  &::after {
    border: 1px solid #555;
    border-left: 0;
    border-top: 0;
    height: 7px;
  }
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: white;
  border: 1px solid white;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999;
}
</style>
